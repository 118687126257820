.c-modal-app-banner.modal-window {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 100;
	overflow: auto;

	.overlay {
		position: fixed;
		top: 0;
		left: 0;
		background-color: #000;
		opacity: 0.6;
		width: 100%;
		height: 100%;
		display: block !important;
	}

	.window-content {
		z-index: 8;
		background-color: #111;
		border-top: 3px solid #f6931a;
		min-height: auto;
		display: table;
		position: absolute;
		bottom: 0;
		width: 100%;
		height: 25vh;
		border-top: none;
		padding: 0;
		.close {
			position: absolute;
			z-index: 999;
			height: 30px;
			opacity: 1;
			width: 30px;
			cursor: pointer;
			top: 15px;
			right: 15px;
			&:after {
				content: '';
				display: block;
				height: 2px;
				left: 50%;
				position: absolute;
				top: 50%;
				width: 17px;
				opacity: .5;
				background-color: #fff;
				transform: translate(-50%, -50%) rotate(135deg);
			}
			&:before {
				content: '';
				display: block;
				height: 2px;
				left: 50%;
				position: absolute;
				top: 50%;
				width: 17px;
				opacity: .5;
				background-color: #fff;
				transform: translate(-50%, -50%) rotate(45deg);
			}
		}
		.dynamic-part {
			display: flex;
			flex-direction: column;
			width: 100%;
			height: 100%;
		}
	}
}

.c-modal-app-banner {
	.window-content {
		.banner-header {
			padding: 20px;
			max-height: 40px;
			display: inline-flex;
			border-bottom: 1px solid #333;

			.icon-app-logo {
				fill: #111;
				overflow: hidden;
				background: #f6931a;
				box-sizing: initial;
				position: relative;
				width: 63px;
				height: 64px;
				padding: 11px 11px;
				top: -50px;
				border-radius: 11px;
				-webkit-box-shadow: 0 5px 20px 5px rgba(247, 147, 26, 0.3);
				-moz-box-shadow: 0 5px 20px 5px rgba(247, 147, 26, 0.3);
				box-shadow: 0 5px 20px 5px rgba(247, 147, 26, 0.3);
			}
		}

		.banner-body {
			h4 {
				margin-top: 0;
				margin-bottom: 16px;
				font-size: 18px;
			}
			.banner-list {
				padding-left: 0;
				list-style: none;
				margin-bottom: 20px;
				li {
					&::before {
						content: "\2022";
						color: #333;
						font-weight: bold;
						display: inline-block;
						width: 1em;
					}
					font-size: 14px;
					padding-bottom: 12px;
				}
			}
			.sbmt-btn2 {
				width: 100%;
				padding-top: 17px;
				padding-bottom: 17px;
				border-radius: 8px;
			}
			.sbmt-btn {
				color: #fff;
				background-color: #f6931a;
				width: 100%;
				display: block;
				text-align: center;
				cursor: pointer;
				outline: none;
				border: 1px solid #f6931a;
				margin: 0 auto;
				border-radius: 8px;
				padding-top: 17px;
				padding-bottom: 17px;
				font-size: 16px;
				max-width: 440px;
				&:hover {
					background-color: #111;
					color: #f6931a;
					border: 1px solid #f6931a;
				}
				&:active {
					background-color: #111;
					color: #f6931a;
					border: 1px solid #f6931a;
				}
			}
			padding: 20px;
		}
	}
}

@media (max-width: 768px) {
	.modal-window {
		.overlay {
			display: none;
			width: 0;
			height: 0;
		}
		.window-content {
			.close {
				width: 30px;
				height: 30px;
			}
		}
	}
}

@media (max-width: 320px) {
	.modal-window {
		.window-content {
			width: 100%;
			height: 100%;
		}
	}
}