@keyframes orderBookSlideDown {
  from {
    max-height: 0;
    background: #333;
  }
  30% {
    max-height: 13px;
  }
  to {
    max-height: 13px;
    background: #333;
  }
}

@keyframes orderBookSlideDownLight {
  from {
    max-height: 0;
    background: #CCC;
  }
  30% {
    max-height: 13px;
  }
  to {
    max-height: 13px;
    background: #CCC;
  }
}

@keyframes orderBookSlideUp {
  from {
    max-height: 13px;
  }
  to {
    max-height: 0;
  }
}

/*#trades .p {
	overflow: hidden;
}*/
@keyframes tradeLogDropDown {
  from {
    max-height: 0;
    background: #333;
  }
  50% {
    max-height: 13px;
  }
  to {
    background: #333;
  }
}

@keyframes tradeLogDropDownLight {
  from {
    max-height: 0;
    background: #CCC;
  }
  50% {
    max-height: 13px;
  }
  to {
    background: #CCC;
  }
}

@keyframes tradeLogDropDownTranslate {
  from {
    transform: translateY(-100%);
    background: #333;
  }
  50% {
    transform: translateY(0);
  }
  to {
    background: #333;
  }
}

@import './components/_mobile_app_bannder.scss';

/*.dialog .tablist .tab:hover {
	text-decoration: underline;
}*/
/*e1f23187*/
form {
  margin: 0;
  padding: 0;
}

dl.form {
  > dt {
    width: 240px;
    text-align: right;
    display: inline-block;
    *display: inline;
    *zoom: 1;
    float: left;
    margin-right: 8px;
    height: 28px;
    line-height: 28px;
  }
  > dd {
    height: 28px;
    line-height: 28px;
  }
}

table.form {
  border-collapse: collapse;
  margin: auto;
  td {
    padding: 4px;
    margin: 0;
  }
  th {
    padding: 4px;
    margin: 0;
    font-weight: normal;
    text-align: right;
  }
  .message {
    text-align: center;
    color: green;
  }
  .error {
    color: red;
  }
}

.full-screen-only {
  display: none !important;
}

html {
  background-color: white;

  &:fullscreen {
    .full-screen-only {
      display: inline-block !important;
    }
    .not-full-screen-only {
      display: none !important;
    }
  }

  &:-moz-full-screen {
    .full-screen-only {
      display: inline-block !important;
    }
    .not-full-screen-only {
      display: none !important;
    }
  }

  &:-webkit-full-screen {
    .full-screen-only {
      display: inline-block !important;
    }
    .not-full-screen-only {
      display: none !important;
    }
  }
}

body {
  overflow: hidden;
  margin: 0;
  font-size: 14px;
  font-family: Arial, sans;
  line-height: 18px;
}

a {
  color: #0D86FF;
  text-decoration: none;
  cursor: pointer;
}

.green {
  color: green;
}

.red {
  color: red;
}

.ab {
  position: absolute;
  left: 0;
  top: 0;
}

ul.horiz {
  list-style: none;
  padding: 0;
  margin: 0;
  > li {
    display: inline-block;
    *display: inline;
    *zoom: 1;
    vertical-align: baseline;
    margin-right: 10px;

    &.spaceholder {
      display: none;
    }
  }
  > li.sep {
    height: 16px;
    vertical-align: middle;
  }
  li.subsep {
    height: 16px;
    vertical-align: middle;
    margin-right: 6px;
  }
  li.addthis {
    position: absolute;
    right: 0;
    margin-top: 6px;
    vertical-align: top;
    width: 190px;
  }
  li.last {
    margin-right: 0;
  }
  input {
    vertical-align: middle;
  }
  select {
    vertical-align: middle;
  }
  button {
    vertical-align: middle;
  }
  .button {
    vertical-align: middle;
  }
}

#control {
  font-family: Arial, sans;
  font-size: 12px;
  font-weight: bold;
  border-bottom: 1px solid #333;
  select {
    font-size: 12px;
    font-family: sans-serif;
    outline: none;
  }
  input {
    font-size: 12px;
    font-family: sans-serif;
    outline: none;
  }
  button {
    font-size: 12px;
    font-family: sans-serif;
    outline: none;
  }
  .button {
    font-size: 12px;
    font-family: sans-serif;
    outline: none;
  }
  i {
    vertical-align: middle;
  }
}

#sidebar_outer {
  float: right;
  height: 100%;
  line-height: 13px;
}

#sidebar {
  height: 100%;
  border-left: 1px solid #333;
  padding-left: 6px;
  #market {
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    height: 18px;
    line-height: 18px;
    font-family: Arial, sans;
    padding: 6px;
  }
  #price {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    height: 28px;
    line-height: 28px;
  }
  #trades {
    text-align: left;
    span {
      font-size: 1.6em;
      font-weight: bold;
    }
    .row {
      display: block;
    }
  }
}

#middlebar {
  width: 120px;
  height: 100%;
  padding: 6px;
  border-right: 1px solid #333;
  text-align: center;
  float: right;
}

#main {
  font-family: Consolas, Monospace;
  font-size: 11px;
  line-height: 14px;
  overflow: hidden;
  display: none;

  #wrapper {
    height: 100%;
    position: relative;
    overflow: hidden;
  }

  .hide_cursor {
    canvas {
      cursor: none;
    }

    #chart_info {
      cursor: none;
    }
  }
}

.footer {
  border-top: 1px solid #333;
  line-height: 28px;

  .icon-sun {
    font-size: 11px;
  }

  .icon-moon {
    font-size: 11px;
  }

  .conn {
    color: #090;
  }

  .horiz {
    text-align: center;
    font-size: 12px;
    font-family: Arial, sans;

    .float-left {
      float: left;
      margin-left: 10px;
    }
  }
}

#assist {
  overflow-y: auto;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 320px;
  height: 32px;
  padding: 0 4px;
  border-left: 1px solid #333;
  border-top: 1px solid #333;
  font-size: 11px;
  line-height: 16px;
  color: #333;
  background-color: rgba(255, 255, 255, 0.8);
}

#info {
  position: absolute;
  font-size: 10px;
  font-family: Consolas, Monospace;
  color: #999999;
}

#leftbar_outer {
  float: right;
  height: 100%;
  #leftbar {
    border-left: 1px solid #333;
  }
  .gg160x600 {
    width: 160px;
    padding: 6px;
  }
}

#close_ad {
  text-align: center;
  padding: 2em;
}

#leftbar {
  height: 100%;
  font-size: 12px;
  position: relative;
  font-family: Consolas, Monospace;
  border-right: 1px solid #ccc;
}

#date {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 16px;
  line-height: 16px;
  text-align: center;
  border-top: 1px solid #ccc;
}

#periods {
  li.period {
    cursor: pointer;
    color: #0D86FF;

    &:nth-child(2) {
      margin-left: 10px;
    }
  }

  li.selected {
    color: #F80;
  }

  .tf-label {
    margin-left: 6px;
    line-height: 26px;
    display: none;
  }
}

.gg468x60 {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 100;
  width: 468px;
  height: 60px;
  border-left: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}

.gg200x200 {
  height: 200px;
  overflow: hidden;
  margin-top: 6px;
  border-top: 1px solid #333;
  padding-top: 6px;
}

.gg728x90 {
  width: 728px;
  height: 90px;
  margin: 16px auto auto;
}

#asks {
  width: 130px;
  .row {
    position: relative;
    width: 120px;
  }
}

#bids {
  width: 130px;
  .row {
    position: relative;
    width: 120px;
  }
}

#gasks {
  width: 80px;
  .row {
    width: 80px;
  }
}

#gbids {
  width: 80px;
  .row {
    width: 80px;
  }
}

.new {
  background-color: #333;
}

#orderbook {
  line-height: 0;
  .orderbook {
    overflow: hidden;
  }
  #asks {
    height: 195px;
    position: relative;
    display: inline-block;
    *display: inline;
    *zoom: 1;
    overflow: hidden;
    .table {
      bottom: 0;
    }
  }
  #gasks {
    height: 195px;
    position: relative;
    display: inline-block;
    *display: inline;
    *zoom: 1;
    overflow: hidden;
    .table {
      bottom: 0;
    }
  }
  #bids {
    height: 195px;
    position: relative;
    display: inline-block;
    *display: inline;
    *zoom: 1;
    overflow: hidden;
    .table {
      top: 0;
    }
  }
  #gbids {
    height: 195px;
    position: relative;
    display: inline-block;
    *display: inline;
    *zoom: 1;
    overflow: hidden;
    .table {
      top: 0;
    }
  }
  .table {
    position: absolute;
    border-collapse: collapse;
    padding: 0;
    margin: 0;
    .row {
      padding: 0;
      margin: 0;
      height: 13px;
      line-height: 13px;
    }
  }
  .remove {
    color: #333;
    g {
      color: #333;
    }
  }
  .amount {
    float: right;
  }
}

.orderbook {
  .table {
    > div {
      overflow: hidden;
    }
    > div.animated {
      &:not(.remove) {
        animation-duration: 1.4s;
        animation-name: orderBookSlideDown;
        animation-timing-function: ease;
      }
    }
    > div.remove {
      animation-delay: 0.8s;
      animation-duration: 0.4s;
      animation-name: orderBookSlideUp;
      animation-fill-mode: forwards;
      animation-timing-function: ease-out;
    }
  }
}

.light {
  #header .horizontal_line {
    border-bottom: 1px solid #CCC;
  }
  .orderbook {
    .table {
      > div.animated {
        &:not(.remove) {
          animation-name: orderBookSlideDownLight;
        }
      }
    }
  }
  .screenshot-container.popup {
    background-color: rgba(255, 255, 255, 1);
  }
  #error_popup.popup {
    background-color: rgba(255, 255, 255, 1);
  }
  background-color: #FFF;
  color: #333;
  g {
    color: #AAA;
  }
  h {
    visibility: hidden;
  }
  a {
    color: #0D86FF;
    &:hover {
      text-decoration: underline;
    }
  }
  #close_ad {
    span {
      color: #0D86FF;
      cursor: pointer;
    }
  }
  .green {
    color: green;
    g {
      color: green;
    }
  }
  .red {
    color: red;
    g {
      color: red;
    }
  }
  #periods {
    li.period {
      color: #0D86FF;
    }
    .selected {
      a {
        color: #F80;
      }
    }
  }
  .modes {
    a.selected {
      color: #F80;
    }
  }
  #drawings {
    a.selected {
      color: #F80;
    }
  }
  .active {
    color: #F80;
  }
  a.active.grey {
    color: #CC6D00;
  }
  #assist {
    background-color: rgba(255, 255, 255, 0.8);
    font-weight: normal;
    color: #333;
    border-color: #CCC;
  }
  #nav {
    border-bottom: 1px solid #CCC;
  }
  #control {
    border-bottom: 1px solid #CCC;
  }
  #before_trades {
    border-bottom: 1px solid #CCC;
  }
  #sidebar {
    border-color: #CCC;
  }
  .footer {
    border-color: #CCC;
  }
  #leftbar_outer {
    #leftbar {
      border-left: 1px solid #CCC;
    }
  }
  #date {
    border-top: 1px solid #CCC;
  }
  ul.horiz {
    select {
      background-color: #FFF;
      color: #333;
      border: 1px solid #666;
    }
    button, .button {
      background-color: #FFF;
      color: #333;
      border: 1px solid #666;
    }
    option {
      background-color: #FFF;
      color: #333;
      border: 1px solid #666;
    }
  }
  .gg468x60 {
    background-color: #FFFFFF;
    border-left: 1px solid #CCC;
    border-bottom: 1px solid #CCC;
  }
  .gg200x200 {
    border-color: #CCC;
  }
  li.sep {
    border-right: 1px solid #DDD;
  }
  li.subsep {
    border-right: 1px solid #DDD;
  }
  #qr {
    border: 1px solid #ccc;
    background-color: rgba(255, 255, 255, 0.8);
  }
  #loading {
    .inner {
      background-color: rgba(255, 255, 255, 0.8);
      border: 1px solid #CCC;
    }
  }
  .dropdown {
    .t {
      border: 1px solid #FFF;
    }
  }
  .dropdown-data {
    background-color: rgba(255, 255, 255, 0.9);
    border: 1px solid #999;
  }
  .dropdown-hover {
    .t {
      color: #666;
      border: 1px solid #999;
      border-bottom: none;
      background-color: rgba(255, 255, 255, 0.9);
    }
    .tr {
      border: 1px solid #999;
    }
    .dr {
      border-top: none;
    }
  }
  #nav-charts {
    td {
      border-bottom: 1px solid #CCC;
    }
  }
  table.simple {
    td {
      border-bottom: 1px solid #CCC;
    }
    ul {
      li {
        color: #0D86FF;
      }
      li.active {
        color: #F80;
      }
    }
  }
  .navbar {
    border-bottom: 1px solid #CCC;
  }
  #trades {
    .v {
      color: #000;
    }
    > div.animate {
      animation-name: tradeLogDropDownLight;
    }
  }
  #now {
    color: #333;
  }
  .difficulty {
    table {
      background-color: white;
      border: 1px solid #CCC;
    }
  }
  .new {
    background-color: #CCC;
  }
  #orderbook {
    .remove {
      color: #CCC;
      g {
        color: #CCC;
      }
    }
  }
  .help {
    background-color: #FFF;
    border: 1px solid #CCC;
  }
  .grey {
    color: grey;
  }
  .ticker_green {
    background-color: green;
    color: white;
  }
  .ticker_red {
    background-color: red;
    color: white;
  }
  .dialog {
    background-color: rgba(255, 255, 255, 1);
    color: #333;
    border: 1px solid #ccc;
    .tablist {
      border-bottom: 1px solid #CCCCCC;
      background-color: #FFFFFF;
      .selected {
        color: #F80;
      }
    }
    .content {
      .selected {
        background-color: #ccc;
      }
    }
  }
  input {
    color: #333333;
    background-color: #FFFFFF;
    border: 1px solid #CCCCCC;
  }
  button, .button {
    color: #333333;
    background-color: #FFFFFF;
    border: 1px solid #CCCCCC;
    &:hover {
      background-color: #E6E6E6;
    }
    &:active {
      background-color: #F2F2F2;
    }
  }
  input[type=button] {
    &:hover {
      background-color: #E6E6E6;
    }
    &:active {
      background-color: #F2F2F2;
    }
  }
  input[type=submit] {
    &:hover {
      background-color: #E6E6E6;
    }
    &:active {
      background-color: #F2F2F2;
    }
  }
  #mode {
    a {
      color: #666;
      &:hover {
        border: 1px solid #eee;
      }
    }
    a.selected {
      border: 1px solid #eee;
      background-color: #eee;
      color: #000;
    }
  }
  #chart_info {
    .inner {
      dd {
        background-color: rgba(255, 255, 255, 0.8);
      }
    }
    .yellow {
      color: #03E;
    }
  }
  #indicator_info {
    .inner {
      dd {
        background-color: rgba(255, 255, 255, 0.8);
      }
    }
  }
  hr {
    border-bottom: 1px solid #E6E6E6;
  }
  .COINWIDGETCOM_WINDOW {
    input {
      color: #333;
      background-color: #FFF;
    }
  }
  .warning {
    color: #F00;
  }
}

#before_trades {
  padding-bottom: 6px;
  border-bottom-width: 1px;
  margin-bottom: 6px;
}

g {
  color: #CCC;
}

h {
  color: white;
}

.donate {
  text-align: center;
  font-size: 12px;
  font-family: Arial, sans;
}

.markets_outer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
}

#market_status {
  font-size: 11px;
  label {
    font-weight: normal;
    margin: 0 6px;
  }
}

abbr {
  cursor: help;
  font-weight: normal;
  border-bottom: 1px dotted #333;
}

#qr {
  border: 1px solid black;
  left: 0;
  top: 0;
  padding: 8px;
  background-color: white;
  position: absolute;
  z-index: 105;
  display: none;
}

.popup {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 5px;
  box-shadow: 0 0 3px #666;
  padding: 12px;
  display: none;
  .drag {
    user-select: none;
    cursor: move;
    position: absolute;
    right: 40px;
    border: 1px solid white;
    padding: 4px 6px;
    border-radius: 6px;
  }
  input {
    width: 2em;
    vertical-align: top;
  }
  h3 {
    border-bottom: 1px solid #333;
  }
  dl {
    dt {
      display: inline-block;
      *display: inline;
      *zoom: 1;
      margin: 0;
      padding: 0;
      margin-right: 6px;
    }
    dd {
      display: inline-block;
      *display: inline;
      *zoom: 1;
      margin: 0;
      padding: 0;
      margin-right: 6px;
    }
  }
  .close {
    text-align: center;
    margin: 12px;
  }
}

.dark {
  #header .horizontal_line {
    border-bottom: 1px solid #333;
  }
  .screenshot-container.popup {
    background-color: rgba(0, 0, 0, 1);
    box-shadow: 0 0 3px #fff;
  }
  #error_popup.popup {
    background-color: rgba(0, 0, 0, 1);
    box-shadow: 0 0 3px #fff;
  }
  background-color: #000000;
  color: #CCC;
  #trades {
    scrollbar-basecolor: #333;
    scrollbar-highlight-color: #CCC;
    scrollbar-shadow-color: #666;
  }
  g {
    color: #666;
    g {
      color: #333;
    }
  }
  h {
    visibility: hidden;
  }
  a {
    color: #6BF;
    &:hover {
      text-decoration: underline;
    }
  }
  #close_ad {
    span {
      cursor: pointer;
      color: #6BF;
    }
  }
  .green {
    color: #0C0;
  }
  .red {
    color: #C00;
  }
  a.active {
    color: #FC9;
  }
  a.active.grey {
    color: #CCA37A;
  }
  #periods {
    li.period {
      color: #6BF;
    }
    .selected {
      a {
        color: #FB6;
      }
    }
  }
  .modes {
    a.selected {
      color: #FB6;
    }
  }
  #drawings {
    a.selected {
      color: #FB6;
    }
  }
  #sidebar {
    .green {
      color: #6C6;
      g {
        color: #336633;
      }
    }
    .red {
      color: #C66;
      g {
        color: #663333;
      }
    }
    #price.green {
      color: #0F0;
    }
    #price.red {
      color: #F00;
    }
  }
  #assist {
    background-color: rgba(0, 0, 0, 0.8);
    font-weight: normal;
    color: #999;
  }
  #nav {
    border-bottom: 1px solid #333;
  }
  #control {
    border-bottom: 1px solid #333;
  }
  #before_trades {
    border-bottom: 1px solid #333;
  }
  #leftbar {
    border-right: 1px solid #333;
  }
  #date {
    border-top: 1px solid #333;
  }
  select {
    background-color: #333;
    color: #CCC;
    border: 1px solid #999;
  }
  option {
    background-color: #333;
    color: #CCC;
    border: 1px solid #999;
  }
  .gg468x60 {
    background-color: #000;
    border-left: 1px solid #333;
    border-bottom: 1px solid #333;
  }
  .markets_outer {
    .markets {
      background-color: #222;
      border: 1px solid #666;
    }
  }
  li.sep {
    border-right: 1px solid #333;
  }
  li.subsep {
    border-right: 1px solid #333;
  }
  #qr {
    border: 1px solid #333;
    background-color: rgba(0, 0, 0, 0.8);
  }
  .popup {
    background-color: rgba(0, 0, 0, 1);
  }
  abbr {
    border-bottom: 1px dotted #CCC;
  }
  .external {
    .green {
      color: #6C6;
    }
    .red {
      color: #C66;
    }
  }
  .help {
    background-color: #000;
    border: 1px solid #333;
  }
  .grey {
    color: grey;
  }
  .ticker_red {
    background-color: #F66;
    color: #000;
  }
  .ticker_green {
    background-color: #6C6;
    color: #000;
  }
  .COINWIDGETCOM_WINDOW {
    input {
      color: #333;
      background-color: #FFF;
    }
  }

  button, .button {
    border: 1px solid #333333;
    background-color: #333333;
    color: #CCCCCC;

    &:disabled {
      background-color: #474747;
    }

    &.selected {
      background-color: #0A0A0A;
    }
  }

  ::-webkit-scrollbar {
    background-color: #333;
    width: 16px;
    height: 13px;
  }

  ::-webkit-scrollbar-button {
    &:start {
      &:decrement {
        height: 0;
        width: 0;
      }
    }
    &:end {
      &:increment {
        height: 0;
        width: 0;
      }
    }
  }

  ::-webkit-scrollbar-track-piece {
    background-color: #222;
    border: 1px solid #555;
  }

  ::-webkit-scrollbar-thumb {
    &:vertical {
      height: 50px;
      background: -webkit-gradient(linear, left top, right top, color-stop(0%, #4d4d4d), color-stop(100%, #333333));
      border: 1px solid #0d0d0d;
      border-top: 1px solid #666666;
      border-left: 1px solid #666666;
    }

    &:horizontal {
      width: 50px;
      background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #4d4d4d), color-stop(100%, #333333));
      border: 1px solid #1f1f1f;
      border-top: 1px solid #666666;
      border-left: 1px solid #666666;
    }
  }
}

#error_popup.popup {
  top: auto;
  bottom: 50px;
  left: 10px;
  width: 200px;
  text-align: center;

  span {
    color: red;
  }
}

#performance_settings {
  width: 320px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

#heatmap_settings {
  table {
    width: 100%;
  }
  .color {
    display: inline-block;
    width: 0.99%;
  }
  .colors {
    width: 100%;
    line-height: 0;
    img {
      width: 100%;
      height: 20px;
    }
  }
  [type=number] {
    width: 8em;
  }
  [type=range] {
    width: 10em;
  }
  td {
    label {
      > input {
        visibility: hidden;
        position: absolute;
        + {
          div {
            cursor: pointer;
            border: 2px solid transparent;
            background: black;
          }
        }
        &:checked {
          + {
            div {
              border: 1px solid #ff6600;
            }
          }
        }
      }
    }
  }
  .container {
    > div {
      display: inline-block;
      width: 49%;
    }
  }
  #heatmap_advanced_settings {
    tr {
      height: 3em;
    }
  }
}

button, .button {
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid #333333;
  background-color: #333333;
  color: #CCCCCC;
  padding: 4px 8px;

  &:hover {
    background-color: #474747;
  }

  &:active {
    background-color: #333333;
  }

  &:disabled {
    cursor: not-allowed;
  }
}

select {
  cursor: pointer;
}

#loading {
  position: absolute;
  top: 20%;
  width: 100%;
  font-size: 18px;
  text-align: center;
  z-index: 99;
  .inner {
    margin-top: -32px;
    line-height: 32px;
    display: inline-block;
    *display: inline;
    *zoom: 1;
    padding: 12px 24px;
    border: 1px solid #333;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.8);
  }
}

.notify {
  position: absolute;
  top: 20%;
  width: 100%;
  font-size: 18px;
  text-align: center;
  z-index: 99;
  display: none;
  .inner {
    margin-top: -32px;
    line-height: 32px;
    display: inline-block;
    *display: inline;
    *zoom: 1;
    padding: 12px 24px;
    border: 1px solid #333;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.8);
  }
}

#donation {
  color: #333;
  font-size: 10px;
  font-family: Consolas, Monospace;
  vertical-align: top;
}

.COINWIDGETCOM_WINDOW {
  input {
    font-size: 11px !important;
  }
}

.COINWIDGETCOM_CONTAINER {
  > span {
    color: #333 !important;
    line-height: 16px !important;
    padding: 0 2px;
  }
  > a {
    height: 16px !important;
  }
}

#mtgoxNav {
  div {
    display: block;
  }
}

.nav-markets {
  font-family: Arial, sans;
  a {
    margin-right: 6px;
  }
}

#markets {
  right: 0;
  font-size: 11px;
  text-align: center;
  font-weight: normal;
  a {
    margin: 0 3px;
  }
  .currency {
    font-size: 12px;
  }
  div {
    display: inline-block;
    *display: inline;
    *zoom: 1;
    font-family: Consolas, Monospace;
    margin-right: 12px;
    margin-left: 0;
    text-align: left;
  }
}

.dropdown {
  padding: 0;
  margin: 0;
  display: inline-block;
  *display: inline;
  *zoom: 1;
  list-style: none;
  position: relative;
  z-index: 10;
  .t {
    border: 1px solid #0A0A0A;
    border-bottom: none;
    padding: 3px 6px;
    z-index: 101;
    position: relative;
    cursor: default;
  }
  .caret {
    margin-left: 6px;
    .icon-caret-down {
      display: inline;
    }
    .icon-caret-up {
      display: none;
    }
  }
}

.dropup {
  padding: 0;
  margin: 0;
  display: inline-block;
  *display: inline;
  *zoom: 1;
  list-style: none;
  position: relative;
  z-index: 10;
  .t {
    border: 1px solid #0A0A0A;
    border-bottom: none;
    padding: 3px 6px;
    z-index: 101;
    position: relative;
    cursor: default;
  }
  .caret {
    margin-left: 6px;
    .icon-caret-down {
      display: inline;
    }
    .icon-caret-up {
      display: none;
    }
  }
}

.dropdown-hover {
  z-index: 100;
  .dropdown-data {
    display: block;
  }
  .t {
    color: #FFF;
    border: 1px solid #666;
    border-bottom: none;
    background-color: rgba(0, 0, 0, 0.9);
  }
  .caret {
    .icon-caret-down {
      display: none;
    }
    .icon-caret-up {
      display: inline;
    }
  }
}

.dropup-hover {
  z-index: 100;
  .dropdown-data {
    display: block;
  }
  .t {
    color: #FFF;
    border: 1px solid #666;
    border-bottom: none;
    background-color: rgba(0, 0, 0, 0.9);
  }
  .caret {
    .icon-caret-down {
      display: none;
    }
    .icon-caret-up {
      display: inline;
    }
  }
}

.dropdown-data {
  display: none;
  position: absolute;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.9);
  padding: 6px;
  margin: 0;
  text-align: left;
  border: 1px solid #666;
  margin-top: -1px;
  li {
    display: block;
    white-space: nowrap;
  }
}

.dropup-data {
  display: none;
  position: absolute;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.9);
  padding: 6px;
  margin: 0;
  text-align: left;
  border: 1px solid #666;
  margin-top: -1px;
  li {
    display: block;
    white-space: nowrap;
  }
}

.dropdown-period.period {
  margin-right: 0;
}

.dropdown-period {
  .dropdown-data {
    text-align: center;
    width: 100%;
  }
  .period {
    padding: 1px 0 2px;
  }
  #dropdown-period-value {
    color: #FB6;
  }
}

.navbar {
  font-family: Arial, sans;
  font-size: 12px;
  border-bottom: 1px solid #333;
  .nav {
    padding: 0;
    margin: 0;
    list-style: none;
    padding-left: 3px;
    li {
      display: inline-block;
      *display: inline;
      *zoom: 1;
      margin-right: 18px;
    }
    li#nav-charts {
      margin-right: 0;
    }

    .logo {
      margin-left: 0;
      font-size: 14px;
      line-height: 40px;

      .dropdown-data {
        min-width: 350px;
      }

      .row {
        display: flex;
        padding: 0;
        width: 100%;
        /*  width: calc(100% + 2.0rem);*/
        flex-direction: row;
        margin-left: -1.0rem;
      }

      .row .column {
        margin-bottom: inherit;
        padding-left: 2rem;

        &:nth-child(2) {
          padding-left: 1rem;
        }
      }
    }

    li.ticker {
      span {
        margin-left: 6px;
        font-family: Consolas, Monospace;
        font-size: 12px;
      }
      span.eprice {
        margin-left: 0;
      }
    }
    li.passport {
      font-size: 13px;
      float: right;
      line-height: 32px;
      height: 32px;
      margin: 0;
      padding: 0;
      > div {
        margin-right: 8px;
        display: inline-block;
        *display: inline;
        *zoom: 1;
      }
    }
  }
  table.simple {
    width: 100%;
  }
}

table.simple {
  font-weight: normal;
  border-collapse: collapse;
  font-size: 12px;
  td {
    padding: 6px;
    border-bottom: 1px solid #333;
  }
  tr {
    &:last-child {
      td {
        border-bottom: none;
      }
    }
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      display: inline-block;
      *display: inline;
      *zoom: 1;
      margin-right: 12px;
      color: #6BF;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
    li.active {
      color: #FC9;
    }
  }
}

.ggtop {
  position: absolute;
  top: 0;
  right: 0;

  img {
    display: block;
  }
}

#trades {
  color: #808080;
  overflow: hidden;
  .row {
    overflow: hidden;
    clear: both;
    height: 13px;
    line-height: 13px;
    position: relative;
  }
  .t {
    display: inline-block;
    *display: inline;
    *zoom: 1;
    vertical-align: top;
  }
  .p {
    display: inline-block;
    *display: inline;
    *zoom: 1;
    vertical-align: top;
  }
  .v {
    display: inline-block;
    *display: inline;
    *zoom: 1;
    vertical-align: top;
    color: #CCC;
    float: right;
    text-align: left;
    width: 5em;
  }
  .s {
    text-align: right;
  }
  > div.animate {
    animation-duration: 1s;
    animation-name: tradeLogDropDown;
  }
  &:hover {
    overflow-y: scroll;
  }
}

#now {
  position: absolute;
  right: 0;
  color: #999;
  cursor: pointer;
}

.watch_list {
  background-color: black;
  table {
    td {
      border: 1px solid #333;
      padding: 6px;
    }
  }
}

.difficulty {
  position: absolute;
  right: 5px;
  top: 5px;
  font-family: Tahoma, Arial, sans;
  font-size: 11px;
  text-align: right;
  table {
    margin: auto;
    border: 1px solid #333;
    border-collapse: collapse;
    text-align: left;
    background-color: #0A0A0A;
    td {
      overflow: hidden;
      height: 16px;
      padding: 0 4px;
      line-height: 16px;
    }
  }
  span {
    font-family: Consolas, Monospace;
  }
}

.more {
  text-align: right;
}

.address {
  font-family: Consolas, Monospace;
}

a.direct_address {
  color: #6C6;
}

.good {
  color: #090;
  .bw-circle {
    background-color: #090;
  }
}

.normal {
  color: #990;
  .bw-circle {
    background-color: #990;
  }
}

.bad {
  color: #900;
  .bw-circle {
    background-color: #900;
  }
}

.unselectable {
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

input {
  padding: 4px;
  border-radius: 4px;
  border: 1px solid #333333;
  background-color: #333333;
  color: #CCCCCC;
}

input[type=text] {
  width: 12em;
}

input[type=password] {
  width: 12em;
}

input[type=checkbox] {
  vertical-align: middle;
}

input[type=button] {
  padding: 4px 8px;
  cursor: pointer;
  &:hover {
    background-color: #474747;
  }
  &:active {
    background-color: #333333;
  }
}

input[type=submit] {
  padding: 4px 8px;
  cursor: pointer;
  &:hover {
    background-color: #474747;
  }
  &:active {
    background-color: #333333;
  }
}

.dialog {
  position: absolute;
  left: 50%;
  top: 40%;
  width: 640px;
  padding: 8px;
  border: 1px solid #333;
  font-family: Arial, sans;
  font-size: 13px;
  background-color: #1A1A1A;
  color: #CCCCCC;
  z-index: 101;
  display: none;

  .tablist {
    border-bottom: 1px solid #333333;
    background-color: #0A0A0A;
    margin-bottom: 8px;
    cursor: move;
    .tab {
      display: inline-block;
      *display: inline;
      *zoom: 1;
      padding: 8px;
      font-size: 12px;
      text-align: center;
      margin-right: 6px;
    }
    .close {
      cursor: pointer;
      width: auto;
      padding: 8px;
      text-align: right;
      float: right;
    }
    .selected {
      color: #FC6;
    }
    i.icon-ok {
      color: #6C6;
    }
    table {
      border-collapse: collapse;
      td {
        padding: 2px 4px;
        &:first-child {
          text-align: right;
          width: 160px;
        }
      }
    }
  }

  .tablist-one {
    .tab {
      width: auto;
    }
  }

  table.s1 {
    width: 100%;
    text-align: center;
    border-collapse: collapse;
    margin-bottom: 8px;
    i.icon-ok {
      color: #6C6;
    }
    i.icon-remove {
      color: #F66;
    }
    td {
      border: 1px solid #4D4D4D;
      padding: 4px;
    }
    th {
      border: 1px solid #4D4D4D;
      padding: 4px;
    }
  }

  h3 {
    margin-top: 0;
    margin-bottom: 8px;
    padding-top: 4px;
    font-size: 12px;
  }

  dl {
    margin-top: 0;
  }

  ol {
    margin: 0;
    padding: 0;
    li {
      margin-left: 40px;
    }
  }

  .error {
    color: #F33;
    text-align: center;
    display: none;
  }

  .rt {
    display: none;
  }

  .ok {
    color: #6C6;
    text-align: center;
  }

  .content_premium {
    table#price_table {
      width: 300px;
      margin: auto;
      margin-bottom: 8px;
    }
    .steps {
      text-align: center;
    }
  }

  .content {
    .selected {
      background-color: #333;
      border-radius: 3px;
    }
  }

  .unit {
    padding: 3px;
    cursor: pointer;
  }
}

.dialog-auto-size {
  width: auto;
  height: auto;
  form {
    table {
      td {
        &:first-child {
          width: auto;
        }
      }
    }
  }
}

.del {
  text-decoration: line-through;

  i {
    text-decoration: none;
  }
}

.ic {
  font-family: FontAwesome;
  font-style: normal;
}

#dlg_alarm {
  min-width: 250px;
}

#dlg_ucp {
  width: 420px;
  .content {
    position: relative;
    height: 250px;
    overflow-y: auto;
  }
  .content_home {
    text-align: center;
    font-size: 13px;
    line-height: 20px;
    dl {
      text-align: left;
      display: inline-block;
      *display: inline;
      *zoom: 1;
      dd {
        margin-bottom: 6px;
      }
    }
    .r {
      text-align: right;
    }
    label {
      text-align: right;
    }
  }
  .content_history {
    text-align: left;
    overflow-y: auto;
    table {
      font-size: 11px;
    }
  }
  .rt {
    width: 420px;
    text-align: center;
    position: absolute;
    top: 0;
  }
}

.content_warning {
  text-align: center;
  .icon {
    float: left;
    font-size: 36px;
  }
}

.content_alarm {
  button {
    font-family: consolas, monospace;
    margin-right: 4px;
  }

  input {
    font-family: consolas, monospace;
  }

  .price {
    width: 6em;
  }

  .all {
    td {
      text-align: center;
    }
  }
}

.content_aggregated_heatmap {
  button {
    font-family: consolas, monospace;
    margin-right: 4px;
  }
}

hr {
  border-bottom: 1px solid #1A1A1A;
}

.help {
  position: absolute;
  left: 0;
  top: 0;
  background-color: #0A0A0A;
  font-size: 11px;
  padding: 8px;
  border: 1px solid #333;
  display: none;
  dl {
    &:first-child {
      margin-top: 0;
    }
  }
}

.bw-circle {
  width: 10px;
  height: 10px;
  display: inline-block;
  *display: inline;
  *zoom: 1;
  background-color: #090;
  vertical-align: baseline;
  border-radius: 3px;
}

#connection {
  .conn {
    font-size: 0;
  }
}

.reason {
  text-align: left;
  font-weight: normal;
  font-size: 14px;
  dt {
    font-weight: bold;
  }
}

#chart_info {
  position: absolute;
  font-family: Consolas, Monospace;
  font-size: 11px;
  width: 100%;
  text-align: left;
  line-height: 12px;
  top: 4px;
  left: 4px;
  dl {
    margin: 0;
    padding: 0;
    margin-right: -18px;
    dd {
      padding: 0;
      margin: 0;
      margin-right: 18px;
      display: inline-block;
      *display: inline;
      *zoom: 1;
    }
  }
  .m {
    display: inline-block;
    *display: inline;
    *zoom: 1;
    vertical-align: top;
    text-align: center;
    width: 1em;
  }
  .yellow {
    color: #EE0;
  }
  .inner {
    display: inline-block;
    *display: inline;
    *zoom: 1;
    dd {
      background-color: rgba(0, 0, 0, 0.8);
    }
  }
}

#indicator_info {
  position: absolute;
  font-family: Consolas, Monospace;
  font-size: 11px;
  width: 100%;
  text-align: left;
  line-height: 12px;
  bottom: 0;
  left: 4px;
  dl {
    margin: 0;
    padding: 0;
    margin-right: -18px;
    dd {
      padding: 0;
      margin: 0;
      margin-right: 18px;
      display: inline-block;
      *display: inline;
      *zoom: 1;
    }
  }
  .m {
    display: inline-block;
    *display: inline;
    *zoom: 1;
    vertical-align: top;
    text-align: center;
    width: 1em;
  }
  .yellow {
    color: #EE0;
  }
  .inner {
    display: inline-block;
    *display: inline;
    *zoom: 1;
    dd {
      background-color: rgba(0, 0, 0, 0.8);
    }
  }
}

#mode {
  font-family: Consolas, Monospace;
  font-size: 12px;
  height: 25px;
  line-height: 25px;
  margin-right: 2px;
  a {
    display: inline-block;
    *display: inline;
    *zoom: 1;
    width: 17px;
    height: 17px;
    margin-right: 6px;
    line-height: 17px;
    text-align: center;
    text-decoration: none;
    color: #999;
    font-weight: normal;
    vertical-align: top;
    margin-top: 3px;
    border-radius: 3px;
    border: 1px solid transparent;
    &:hover {
      border: 1px solid #333;
      color: #ccc;
    }
  }
  a.selected {
    border: 1px solid #333;
    color: #ccc;
    color: #ccc;
    border: 1px solid #333;
  }
  img {
    vertical-align: middle;
    width: 13px;
    height: 13px;
  }
}

.hint {
  font-size: 11px;
  margin-left: 20px;
  font-family: Tahoma, Arial, sans;
}

code {
  font-family: Consolas, Monospace;
}

.warning {
  color: #FF0;
  font-size: 14px;
  font-family: Tahoma, Arial, sans;
  padding: 4px 8px;
  font-weight: bold;
}

.hide {
  display: none;
}

.unit_k {
  color: #00FF00;
}

.unit_m {
  color: #FFFF00;
}

.unsel {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.center {
  text-align: center;
}

.template {
  display: none;
  margin: auto;
}

.desktop-only {
  display: none !important;
}

.difficulty {
  display: none;
}

.eprice {
  display: none;
}

.popup {
  width: calc(100vw - 30px);
  top: 10px;
  left: 3px;
  z-index: 20;

  .drag {
    display: none;
  }
}

.screenshot-container.popup {
  top: 3px;
  text-align: center;

  img {
    max-width: 100%;
    max-height: 50vh;
  }
}

#heatmap_settings.popup {
  top: 3px;
}

#nav-charts {
  font-weight: normal;
  display: none;
}

#mode {
  display: none;
}

#sidebar {
  width: 8px;

  #expand_sidebar {
    opacity: 0.8;
    cursor: pointer;
    height: 100%;
    margin-left: -6px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.navbar {
  .nav {
    li.ticker {
      display: none;
    }

    .logo .dropdown .t {
      padding: 0 6px;
    }

    .dropdown .t {
      padding: 3px 6px 3px;
    }

    .logo {
      margin-right: 0;

      img {
        width: 28px;
        margin-bottom: -9px;
      }

      > a {
        display: none;
      }
    }
  }
}

#header {
  .horizontal_line {
    display: block;
  }

  ul.horiz {
    padding: 0;

    ul {
      display: inline-block;
    }

    li {
      padding: 4px 0;

      &.sep {
        padding: 0;
      }

      &.subsep {
        padding: 0;
      }
    }

    .dropdown-data {
      padding: 3px;
    }

    .li_settings {
      padding: 0;
      margin-left: 3px;
      margin-right: 0;

      table.simple td {
        padding: 3px;

        ul li {
          padding: 0;
        }
      }

      .dropdown-data-full-size {
        width: calc(100vw - 8px);
      }
    }

    .li_tools {
      padding: 0;
    }
  }
}

/* hideable - display - size */
.hideable-inline-block-mobile {
  display: none !important;
}

.hideable-block-mobile {
  display: none !important;
}

.hideable-inline-block-phablet {
  display: none !important;
}

.hideable-block-phablet {
  display: none !important;
}

.hideable-inline-block-tablet {
  display: none !important;
}

.hideable-block-tablet {
  display: none !important;
}

.hideable-inline-block-desktop {
  display: none !important;
}

.hideable-block-desktop {
  display: none !important;
}

.hideable-inline-block-desktop-large {
  display: none !important;
}

.hideable-block-desktop-large {
  display: none !important;
}

/* hideable - display - size1-size2 */

body {
  overflow: auto;
}

.dropdown-data {
  table.simple {
    width: 100%;

    td:nth-child(1) {
      white-space: nowrap;
    }
  }

  &.dropdown-data-full-size {
    width: calc(100vw - 16px);
  }
}

.nowrap {
  white-space: nowrap;
}

/* Easter eggs */
.logo .supercycle-logo {
  transform: scaleY(-1);
  vertical-align: super;
  height: 16px;
}

$breakpoint-mobile: 400px !default;
$breakpoint-phablet: 550px !default;
$breakpoint-tablet: 767px !default;
$breakpoint-desktop: 1000px !default;
$breakpoint-desktop-hd: 1200px !default;

@if $forceMobile {
  $breakpoint-mobile: 1px;
  $breakpoint-phablet: 2px;
  $breakpoint-tablet: 3px;
  $breakpoint-desktop: 4px;
  $breakpoint-desktop-hd: 1200px;
}

/* Larger than mobile */
@media (min-width: $breakpoint-mobile) {
  .hideable-inline-block-mobile {
    display: inline-block !important;
  }

  .hideable-block-mobile {
    display: block !important;
  }
}

/* Larger than phablet (also point when grid becomes active) */
@media (min-width: $breakpoint-phablet) {
  #sidebar {
    width: 220px;

    #expand_sidebar {
      display: none;
    }
  }

  .hideable-inline-block-phablet {
    display: inline-block !important;
  }

  .hideable-block-phablet {
    display: block !important;
  }
}

@media (min-width: $breakpoint-phablet) and (max-width: $breakpoint-tablet) {
  ul.horiz > li.spaceholder {
    display: inline-block;
  }

  .float-right-phablet {
    float: right;
  }

  .visible-inline-block-phablet-tablet {
    display: inline-block !important;
  }

  .hideable-inline-block-phablet-tablet {
    display: none !important;
  }
}

/* Larger than tablet */
@media (min-width: $breakpoint-tablet) {
  #header .horizontal_line {
    display: none;
  }

  .hideable-inline-block-tablet {
    display: inline-block !important;
  }

  #nav-charts {
    display: inline-block;
  }

  #header ul.horiz .li_settings {
    margin-right: 10px;
    margin-left: 0;

    .dropdown-data {
      white-space: nowrap;
    }

    table.simple td {
      padding: 6px;
    }

    .dropdown-data-full-size {
      width: auto;
    }
  }
  
  .nav-markets .dropdown-data table.simple {
    td:nth-child(2) {
      white-space: nowrap;
    }
  }

  .dropdown-data.dropdown-data-full-size {
    width: auto;

    table {
      white-space: nowrap;
    }
  }
}

/* Larger than desktop */
@media (min-width: $breakpoint-desktop) {
  .dropdown-data {
    width: auto;

    table.simple {
      width: auto;
    }
  }

  #now {
    padding-right: 6px;
  }

  #header .inner > ul.horiz {
    padding-left: 6px;

    li {
      padding: 0;
    }
  }

  .navbar {
    .nav li.ticker {
      display: inline-block;
    }
  }

  .hideable-block-desktop {
    display: block !important;
  }

  .hideable-inline-block-desktop {
    display: inline-block !important;
  }

  .screenshot-container.popup {
    top: 70px;

    img {
      max-height: 100vh;
    }
  }

  #heatmap_settings.popup {
    top: 70px;
  }

  #mode {
    display: inline-block;
  }

  .navbar .nav .logo {
    margin-left: 3px;
    margin-right: 18px;

    > a {
      display: inline;
    }

    > div {
      display: none;
    }
  }

  .popup {
    width: 60%;
    left: 20%;
    top: 20%;

    .drag {
      display: block;
    }
  }

  .dark {
    .popup {
      background-color: rgba(0, 0, 0, 0.6);
    }
  }

  #periods {
    .tf-label {
      margin-left: 0;
      display: inline-block;
    }

    li.period:nth-child(2) {
      margin-left: 0;
    }
  }
}

/* Larger than Desktop HD */
@media (min-width: $breakpoint-desktop-hd) {
  .desktop-only {
    display: block;
  }

  .difficulty {
    display: block;
  }

  .hideable-block-desktop-large {
    display: block !important;
  }

  .hideable-inline-block-desktop-large {
    display: inline-block !important;
  }
}